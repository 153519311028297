<template lang="html">
  <div>
    <template v-if="!loading">
      <v-card outlined shaped v-for="(item, i) in laporan" :key="i">
        <v-card-title style="font-size:14px;">{{item.nama_aktifitas}}</v-card-title>
        <div v-for="(isi, i) in item.isi_aktifitas" :key="i">
          <v-card-text class="paragraph d-flex flex-column mt-2" style="font-size:12px" >
            <span class="mt-1">Aktivitas Telah Dilakukan Pada Pukul {{isi.jam_mulai | addZero}}:{{isi.menit_mulai| addZero}} sampai {{isi.jam_selesai|addZero}}:{{isi.menit_selesai|addZero}}</span>

            <span class="mt-1"><span class="font-weight-medium">Dengan volume</span> {{isi.volume}} x {{item.satuan_output}}</span>

            <span class="mt-1"><span class="font-weight-medium">Keterangan :</span> {{isi.keterangan}}</span>

            <span class="mt-1"><span class="font-weight-medium">Keterangan Validasi :</span> {{ isi.keterangan_validasi }}</span>
          </v-card-text>
          <v-card-actions>

            <v-chip
            v-if="isi.status == 'valid'"
            class="ma-2"
            color="success"
            text-color="white"
            >
            <v-icon small left>
              mdi-check
            </v-icon>
            Aktivitas Valid
          </v-chip>

          <template v-else-if="isi.status == 'invalid'">
            <v-chip
            class="ma-2"
            color="error lighten-1"
            text-color="white"
            >
              <v-icon small left>
                mdi-close
              </v-icon>
              Aktivitas Tidak Valid
            </v-chip>
            <v-spacer></v-spacer>
            <v-btn small class="error"><v-icon small left>mdi-delete</v-icon> Hapus</v-btn>
            </template>

            <template v-else>
              <v-spacer></v-spacer>
              <v-btn small @click="dialog(isi, item.nama_aktifitas, item.satuan_output)" class="primary"><v-icon small left>mdi-pencil</v-icon> Ubah</v-btn>
              <!-- <v-btn small class="error" @click="hapus(isi)"><v-icon small left>mdi-delete</v-icon> Hapus</v-btn> -->
              <confirm-btn title="Hapus Laporan" :content="'Hapus aktivitas ini ?'" @on-agree="hapus(isi)" :btnProps="{'name':'hapus', color:'error', small:true, icon:'mdi-delete', class:{'my-1':true,'mx-2':true}}"></confirm-btn>
            </template>
          </v-card-actions>
          <v-divider></v-divider>
        </div>
      </v-card>
      <v-card shaped class="paragraph" v-if="laporan.length == 0">
        <v-card-text>Tidak Ada Aktivitas</v-card-text>
      </v-card>
    </template>
    <template v-else>
      <v-skeleton-loader class="mt-5" v-for="i in [1,2]" :key="i"
          type="article, actions"
      ></v-skeleton-loader>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import PelaksanaService from '@/services/PelaksanaService'
import ConfirmBtn from '@/components/base/ConfirmBtn'
export default {
  components:{
    ConfirmBtn
  },
  props:{
    laporan:Array,
    loading:Boolean,
  },
  filters:{
    addZero:function(value){
      if (value*1 < 10){
        return '0'+value
      }
      return value
    }
  },
  methods:{
    dialog(item, nama_aktifitas, satuan_output){
      item['nama_aktifitas'] = nama_aktifitas
      item['satuan_output'] = satuan_output
      this.$emit('callDialog', item)
    },
    hapus(isi){
      PelaksanaService.hapusIsiAktivitas(isi.id).then(response =>{
        let data = response.data
        console.log(data)
        if (data.success){
          store.commit('snackbar/setSnack',{message:data.message, color:'success'})
        }else{
          store.commit('snackbar/setSnack',{message:data.error, color:'error'})
        }

        this.$emit('update-laporan', isi.jenis)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
